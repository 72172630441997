export default defineI18nConfig(() => ({
  datetimeFormats: {
    'en': {
        monthName: {
             month: 'long'
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        full: {
            year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
        }
    },
    'es': {
        monthName: {
                month: 'long'
        },
      short: {
        year: 'numeric', month: 'short', day: 'numeric'
      },
        full: {
            year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
        }

    },
      'de': {
          monthName: {
              month: 'long'
          },
          short: {
              year: 'numeric', month: 'short', day: 'numeric'
          },
          full: {
              year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
          }

      },
      'fr': {
          monthName: {
              month: 'long'
          },
          short: {
              year: 'numeric', month: 'short', day: 'numeric'
          },
          full: {
              year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
          }

      }
      ,
      'zh': {
          monthName: {
              month: 'long'
          },
          short: {
              year: 'numeric', month: 'short', day: 'numeric'
          },
          full: {
              year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'
          }

      }

  },
    locale: 'en',
    globalInjection: true,
    fallbackLocale: 'en',
  }))

